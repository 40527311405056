.tritBalance {
  position: relative;
  top: 6px;
  left: -10px;
}

.balanceTritAmount {
  position: relative;
  top: 8px;
}

.balance {
  font-size: 26px;
  text-align: right;
  font-weight: 600;
  color: aliceblue;
  border-radius: 5px;
  background-color: #243638;
  width: 100%;
  border-color: #89958777;
  border-width: 1px;
  border-style: solid;
  height: 42px;
}

.balanceTritSymbol{
  padding: 4px;
}

.decimalPartBalance {
  font-size: 10px; 
}

@media screen and (max-width: 826px) {
  .decimalPartBalance {
    position:relative;
    top:0px; 
  }

  .balance {
  position:relative;
  top:8px
  }
}

