@font-face {
  font-family: oxanium;
  src: url("fonts/Oxanium-Regular.ttf");
}

* {
  box-sizing: border-box;
}
.App {
  text-align: center;
  font-family: oxanium !important;  
}

body {
  background-image: url("images/bg.jpg");
  background-repeat: no-repeat;
  background-color: #977643;
  width: 100%;
  height: 100%;
  font-family: oxanium;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 30px;
}

.App-link {
  color: #61dafb;
}

.TopBar {
  width: 100%;
  text-align: center;
  background-color: #00000048;
  height: 50px;
}

.TopBarContent {
  width: 900px;
  text-align: right;
  padding: 10px;
  margin: auto;
}

.boxTritBalance {
  padding: 2px;
  text-align: center;
  justify-content: center;
  margin: 10px;
}

.supply {
  font-size: 13px;
  font-weight: bolder;
  color: #ffffffb2;
  margin-right: 10px;
  text-align: left;
  position: relative;
  top: -24px;
  width: 210px;
}

.supply img {
  opacity: 0.7;
}

.valueSupply {
  position: relative;
  top: -30px;
  left: 30px;
}

.preSaleTable .title {
  font-weight: bold;
  font-size: 7pt; 
  margin-bottom: 20px;
}

.preSaleTable table {
  position: relative;
  bottom:25px;  
  color: rgba(255, 255, 255, 0.2);
  width: 100%;
  border-collapse: collapse;
}

.maxBalance{
  z-index: 10;
  position:relative;
  left:0px;
  top:46px;
  width:70px;  
  padding:2px;
  height: 15px;
  cursor: pointer;  
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  background-color: #db7901; 
  border-radius: 1px;
  color: #000;
}

.preSaleTable th, .preSaleTable td {
  font-size: 14px;
  border: 1px solid #db790127;
  padding: 5px;
  text-align: center;
}

.preSaleTable tr.selected {  
  background-color: #db790127; 
  color:white;
}

.preSaleTable th {
  background-color: #db7901;
  color: white;
}

.feeInfo {
  text-align: right;
  font-size: 12px;
  color: #ffffff70;
  margin: 10px;
}

.feeNumber {
  background-color: #000;
  border-radius: 4px;
  padding: 6px;
}

.redNumber {
  background-color: red;
  border-radius: 4px;
  padding: 6px;
  color: #fff;
}

.logo {  
  width: 340px;
  filter: opacity(0.9) drop-shadow(0 0 0.09rem #fff);
  margin: 8px;
}

.coinsOptions {
  width: 180px;
  padding: 6px;
}

.coinOption {
  margin: 6px;
  padding: 6px;
  background-color: #bbb;
  border-radius: 2px;
  cursor: pointer;
}

.coinOption:hover {
  margin: 6px;
  padding: 6px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #aaa;
}

.content {
  text-align: center;
  width: 900px;
  margin: auto;
}

.box {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.Menu {
  text-align: center;
  padding-left: 10px;
  margin-bottom: 20px;
}

.Menu button {
  margin: 8px;
  border-radius: 12px;
  background-color: #999999;
}

.Menu button:hover {
  margin: 8px;
  border-radius: 12px;
  background-color: #db7901;
}

.socialLinks a{
  position:relative;
  bottom:10px;
  margin: 8px;
}

.scifi {
  width: 740px;
  color: white !important;
  padding:20px;
  border: 0;
  display: grid;  
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  appearance: none;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  word-spacing: 3px;
  letter-spacing: 0.4px;
  transition: 0.3s;
  line-height: initial;
  z-index: 1;
  outline: 0;
  background-color: transparent;
  vertical-align: top;
  margin: 0 auto;
}

.scifi:before,
.scifi:after {
  content: "";
  inset: 0;
  background: #db7901;
  position: absolute;
  z-index: -1;
  clip-path: polygon(
    0 12px,
    12px 0,
    calc(40% - 8px - 10px) 0%,
    calc(calc(40% - 8px - 10px) + 8px)
      8px,
    calc(calc(60% - 8px - 10px) + 8px + 20px)
      8px,
    calc(
        calc(60% - 8px - 10px) + 8px + 20px +
        8px
      )
      0,
    calc(100% - 12px) 0,
    100% 12px,
    100% calc(40% - 8px - 5px),
    calc(100% - 8px)
      calc(40% - 8px - 5px + 8px),
    calc(100% - 8px)
      calc(60% - 8px - 5px + 8px + 10px),
    100%
      calc(
        60% - 8px - 5px + 8px + 10px +
        8px
      ),
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    calc(
        calc(60% - 8px - 10px) + 8px + 20px +
        8px
      )
      100%,
    calc(calc(60% - 8px - 10px) + 8px + 20px)
      calc(100% - 8px),
    calc(calc(40% - 8px - 10px) + 8px)
      calc(100% - 8px),
    calc(calc(40% - 8px - 10px)) 100%,
    12px 100%,
    0 calc(100% - 12px),
    0
      calc(
        calc(60% - 8px - 5px) + 8px + 10px +
        8px
      ),
      8px
      calc(calc(60% - 8px - 5px) + 8px + 10px),
      8px
      calc(calc(40% - 8px - 5px) + 8px),
    0 calc(calc(40% - 8px - 5px))
  );
  box-shadow: inset 0 0 25px -10px #000000ec;
}

.scifi:after {
  inset: 1px;
  background: #000000ec;
}

.helper1 {
  content: "";
  position: absolute;
  inset: calc(8px / 2);
  background: #db7901;
  z-index: -2;
  clip-path: polygon(
    0% 5%,
    0% 95%,
    5% 100%,
    95% 100%,
    100% 95%,
    100% 5%,
    95% 0%,
    5% 0%
  );
}

.helper1:after {
  left: unset;
  right: -1px;
}

[class*="scifi"].disabled,
[class*="scifi"]:disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.Col {
  display: grid;
}

.Row {
  display: flex;
}

.nftAlert {  
  color: gray;
  padding: 6px;
  width: 100%;
  font-size: 13px;
  background-color: #000;
  margin: 6px;
  border-radius: 2px;
}

.btnConect {
  background-color: transparent;
  border-radius: 5px;
  border: solid 1px #db7901;
  color: #db7901;
  padding: 6px;
  width: 140px;
  cursor: pointer;
  font-family: oxanium;
}

.btnSwap button{
  margin: 20px;
  width: 320px;
  height: 50px;
}

/* COIN EXCHANGES */
.coinBox {
  width: 100%;
  height: 65px;
  padding: 14px;
  background-color: #db790127;
  border-radius: 5px;
}

.coinBoxDisabled {
  width: 100%;
  height: 65px;
  padding: 14px;
  background-color: #db790127;
  border-radius: 5px;
  filter: grayscale(1);
  opacity: 0.5;
}

.selectCoin {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
  text-align: right;
  cursor: pointer;
}

.valueCoin {  
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  position:relative;
  left: 0px;
}

.valueCoinTrit {
  position:relative;
  left:0px;      
}

.logoCoin {
  text-align: left;
}

.logoCoin img {
  width: 40px;
  height: 40px;
}

.changeCoin {
  cursor: pointer;
}

.symbolCoin {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  position: relative;
  margin-left: 10px;
  position: relative;
  top: -4px;
}

.nameCoin {
  font-size: 12px;
  text-align: left;
  position: relative;
  color: #ffffff70;
  width: 140px;
  margin-left: 10px;
  position: relative;
  top: -4px;
}

.titleSection {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 18px;
  margin-top: 5px;
}

.balanceTritSymbol {
  float: left;
  margin-right: 10px;
}

.balanceTritSymbol img{
  width: 30px;
}

.balanceTritAmount {
  float: left;
  font-size: 20px;
  color: #ffffff;
  position: relative;
  top: 6px;
}

.decimalPart {
  font-size: 13px; 
}

.decimalPartLunc {
  font-size: 10px; 
}

.decimalPartTop {
  font-size: 12px; 
}

.reference{
  text-align: left;
  font-size: 12px;
  color: #ffffff70;
  cursor: pointer;
}

.addressRef {
  font-size: 12px;
  color: #ffffff70;
  background-color: #000;
  border-radius: 5px;
  padding: 6px;
}

.textRefLink {
  font-size: 12px;
  color: #555;
}

.youRefferr {
  font-weight: bold;
  background-color: #db7901; 
  border-radius: 5px;
  color: #000;
  padding: 6px;
  text-align: center;
}

.modal {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: #34a21c;
  border: 2px solid #db7901;
  width: 600px;
  height: 300px;
  text-align: center;
}

.dialogContent {
  width: 420px;
}

.stakeIntroduction {
  background-color: #3c3d39;
  border-radius: 5px;
  margin: 2px;
  padding: 16px;
  font-size: 11px;
  text-align: left;
  color: #999999;
  border: 1px dashed #000;
  width: 100%;
}

.stakeBoxDisabled {
  filter: grayscale(1);
}

.valueMiniButton {
  background-color: #db7901;
  border-radius: 5px;
  color: #fff;
  padding: 3px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.stakeUnitDetail {
  background-color: #3c3d39;
  border-radius: 5px;
  margin: 2px;
  padding: 16px;
  font-size: 11px;
  text-align: left;
  color: #999999;
}

.stakeSlotInfos {
  border-radius: 5px;
  padding: 4px;
}

.stakeMyInfos {
  border-radius: 5px;
  background-color: #00000081;
  padding: 10px;
}

.valueStake {
  background-color: #3c3d39;
  padding: 6px;
  border-radius: 5px;
  margin-top: 6px;
  text-align: right;
}

.stakeLabel {
  font-size: 12px;
  color: #ffffff70;
  margin-top: 10px;
  font-weight: bold;
}

.stakeSlot {
  background-color: #ffffff13;
  border-radius: 10px;
  margin: 10px;
  width: 580px;
}

.btnExplore {
  background-color: #db7901;
  border-radius: 8px;
  color: #000;
  padding: 6px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: -10px;
  left: -12px;
  cursor: pointer;
}

.btnExplore:hover {
  background-color: #d18323;
}

.exploreIcon {
  position: relative;
  top: 7px;
}

input[type="number"] {
  font-family: oxanium !important;
  outline:none !important;
  background-color: #00000000 !important;
  color: #ffffff !important;
  border: 0 !important;
  font-size: 26px !important;
  text-align: right !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stakeImage {
  text-align: center;
  padding: 10px;
}

.stakeDesc {
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: left;
}

.stakeDescValue {
  color: #d1d3c9;
}

.stakeDescValueTag {
  border-radius: 6px;
  background-color: #27af9f;
  color: #fff;
  padding:4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}

.stakeDescValueTag2 {
  border-radius: 6px;
  background-color: #db7901;
  color: #fff;
  padding:4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}


@media screen and (max-width: 926px) {
  body {
    background-image: url("images/bg.jpg");
    background-size: 519%;
    background-position: 80% -30px;
    background-repeat: no-repeat;
    font-family: oxanium;    
  }

  .stakeSlot {
    background-color: #ffffff13;
    border-radius: 10px;
    margin: 10px;
    width: 340px;
  }

  .stakeDesc {
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }

  .valueStake {
    font-size: 12px;
    background-color: #3c3d39;
    padding: 6px;
    border-radius: 5px;
    margin-top: 6px;
  }
  
  .stakeLabel {
    font-size: 11px;
    color: #ffffff70;
    margin-top: 10px;
    font-weight: bold;
  }

  .boxTritBalance {
    padding: 2px;
    text-align: center;
    justify-content: center;
    margin: 10px;
  }

  .scifi {
    position: relative;
    bottom:30px;
    margin-bottom:40px;    
    width: 360px;    
    display: grid;
    padding: 10px;    
  }  

  .Menu {      
    position: relative;
    right:4px;  
    width:auto !important;    
    margin-bottom: 60px;
    display:flex;    
  }

  .gameButton {
    background-color: '#000000';
    opacity: '0.9'; 
    color: '#555';
  }

  .content {
    width: 100%;
    padding: 0px 0;    
    overflow: hidden;           
  }
  

  .footer{
    position: relative;
    top:50px
  }

  .balanceTritSymbol img{
    position:relative;
    top:0;
    margin:5px;
    width: 25px;
  }

  .balanceTritAmount {
    float: left;
    font-size: 15px;
    color: #ffffff;
    position: relative;
    top: 13px;
  }   

  .TopBarContent {
    width: 100%;
    padding: 3px;
  }

  .btnConect {
    position:relative;
    right:10px;
    top:8px;
    width: 130px;
  }

  .btnSwap button {
    position:relative;
    top:10px;
    margin: 10px;
    width: 250px;
    height:30px;
  }

  .coinBox {   
    position:relative;
    left:10px; 
    width: 330px;    
    padding: 10px;  
  }
  
  .coinBoxDisabled {
    position:relative;
    left:10px;
    width:330px;
    height: 60px;
    padding: 10px;  
  }
  
  .selectCoin {
    width: 10px;
    height: 20px;
    position: relative;
    top: 4px;
    text-align: right;
    cursor: pointer;
    z-index: 10;
  }

  .valueCoin{
    position:absolute;
    display: block;
    left:0;
    width: 60%; 
    text-align: right;
    margin: 0px;
    float: right; 
  }
  
  .valueCoinTrit {
    position:absolute;
    left:-60px;      
  }

  .decimalPart {
    position:relative;
    bottom:0px;
    left:0px
  }
  
  .logoCoin img {
    position:relative;
    top:5px;
    width: 35px;
    height: 35px;
    z-index: 10;
  }  

  .symbolCoin {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    position: relative;
    margin-left: 10px;
    position: relative;
    top: -4px;
    z-index: 10;
  }

  .socialLinks a{
    position:relative;
    bottom:50px;
    margin: 8px;
  }

  .titleSection {
    position: relative;
    bottom:40px
  }

  .youRefferr {
    font-weight: bold;
    background-color: #db7901; 
    border-radius: 5px;
    color: #000;
    padding: 6px;
    text-align: center;
    font-size: 10px;
  }

  .nameCoin {
    font-size: 12px;
    text-align: left;
    position: relative;
    color: #ffffff70;
    width: 140px;
    margin-left: 10px;
    position: relative;
    top: -4px;
    z-index: 10;
  }

  .dialogContent {
    width: 300px;
  }
}